@import 'variables';

@mixin fa {
  font-family: $font-awesome-font-name;
  font-weight: 900;
  @include fa-margins;
}

@mixin fa-margins {
  font-size: 1.6rem;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
}

@mixin full-parent-size {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

@mixin text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
