@import 'mixins';
@import 'palette';

// Hide things that should not display.
.main.loading,
    // Raw vue templates never shows up.
#vue-templates,
    // Component placeholder is used by JS only.
.com-init,
    // Used to mask whole page.
#main-overlay {
  display: none;
}

// Used to mask main page content
// (i.e. to open a modal or a mobile menu).
#main-overlay {
  @include full-parent-size;
  // Active content should start to z-index 1000.
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);

  &.visible {
    display: block;
  }
}

.simple-text {
  color: $dark-gray;
}

.container-medium {
  max-width: 60rem;
  margin: 0 auto;
}

.container-large {
  margin: 0 2rem;
}

.picture {
  overflow: hidden;

  .picture-content {
    padding-bottom: 100%;
    background-size: cover;
    width: 100%;
    height: 100%;
    // Don't optimize.
    background-position: center;
    background-repeat: no-repeat;
  }
}

.text-ellipsis {
  @include text-ellipsis;
}

.new {
  @keyframes new {
    from {
      background: rgba(248, 217, 106, 0.34);
    }
    to {
      background: inherit;
    }
  }

  animation: 4s new forwards;
}

.secondary {
  opacity: 0.5;
}

.flex-fix {
  width: 100%;
  min-width: 0;
}

.flex-width-fixed-small {
  min-width: 6rem;
  max-width: 6rem;
}

.flex-width-fixed-medium {
  min-width: 12rem;
  max-width: 12rem;
}
