.cols {
  display: flex;

  .cols-col,
  .cols-col-centered {
    flex-shrink: 0;
    min-width: 3rem;
  }

  .cols-col-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cols-body {
    flex-shrink: 1;
    width: 100%;
    min-width: 0;
  }
}
