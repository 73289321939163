$block-border-radius: 0.8rem !default;
$block-padding-x: 1rem !default;
$block-padding-y: 1rem !default;
$block-shadow: 0 0.1rem 0.1rem rgba(0, 0, 0, 0.15),
  0 0.04rem 0.8rem rgba(0, 0, 0, 0.09) !default;
$block-spacing: 0.5em !default;
$block-text-secondary: rgba(0, 0, 0, 0.4) !default;
$blocks-side-column-width: 16rem;
$burger-color: rgba(0, 0, 0, 0.6) !default;
$burger-line-size: 4px; // Pixels have better rendering.
$button-size: 1.6rem;
$font-awesome-font-name: 'Font Awesome 5 Free';
$form-label-color: #9e9e9e;
$html-text-color: rgba(0, 0, 0, 0.87) !default;
$main-side-nav-color: #fff !default;
$main-side-nav-background: $main-side-nav-color !default;
$main-side-nav-width: 16rem !default;
$main-side-nav-width-reduced: 4rem !default;
$main-side-nav-reduce-width: 30px !default; // Uses px as rem does not renders proper rounds.
$messages-types: ('error', 'info', 'success', 'warn');
$modal-border-radius: 1rem;
$page-side-nav-width: 18rem !default;
$user-menu-hover: rgba(0, 0, 0, 0.04) !default;
