@import 'palette';
@import 'mixins';

// Overrides material animated labels and fix fields overlaps.
.input-field > label:not(.label-icon) {
  transform: translateY(-14px) scale(0.8) !important;
  transform-origin: 0 0 !important;
}

.form-input-helper {
  color: $medium-gray;
  padding: 0.2rem;
}

.form-errors {
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.form-errors-no-error {
  display: none;
}

input.no-outline {
  outline: none;
}

input[type='text']:not(.browser-default),
input[type='password']:not(.browser-default) {
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  border-radius: 0.3rem;
  margin-top: 0.3rem;
}

// Allow to remove arrows on number fields.
input[type='number'].no-number-arrows {
  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
}

.input-field {
  .prefix {
    color: $light-gray;
  }

  .prefix.active {
    color: $medium-gray;
  }

  & label {
    @include text-ellipsis;
  }
}

.input-field-required {
  position: absolute;
  top: -14px;
  right: 0;
  color: $forms-error;
  font-size: 16px;

  // Support all fields colors when disabled.
  &.disabled {
    opacity: 0.2;
    // Support dark backgrounds.
    text-shadow: #fff 0 1px, #fff 0px -1px, #fff 1px 0px, #fff -1px 0px,
      #fff 0px 0px 50px;
  }
}

.helper-text .material-icons {
  color: inherit;
  font-size: 15px;
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -4px;
}

.input-field:not(.field-checkbox) .helper-text:not(.date-widget) {
  margin-top: -7px;
}

// Text
// Adjust counter position.
.character-counter {
  min-height: 18px;
  display: block;
  text-align: right;
}

// Checkbox
.field-checkbox label {
  position: initial;
}

body .input-field.field-checkbox,
body .input-field.field-switch {
  .helper-text {
    margin: 0 0 0 3rem;
  }

  .switch-label {
    min-height: 1.9rem;
    margin-bottom: 1.5rem;
    color: $form-label-color;
    padding-top: 0.2rem;
  }

  .error-text {
    clear: both;
    margin-top: -1.5rem;
  }
}

body .input-field.field-checkbox {
  .switch-label {
    padding-left: 3rem;
  }
}

body .input-field.field-switch {
  .switch {
    float: left;
    width: 3rem;

    label {
      min-height: 3rem;

      .lever {
        margin: 0;
      }
    }
  }
}

:not(.date-widget) .field-choice {
  .select-wrapper {
    margin-bottom: 26px;
  }

  // Override default values.
  &:not(.field-checkbox) .helper-text:not(.date-widget) {
    margin-top: -27px;
  }
}

.field-choice ~ label {
  margin-left: 3rem;
  top: 0;
}

// Date
.date-widget {
  & .row {
    margin: 0;
  }

  & ~ .helper-text {
    clear: both;
  }

  input[type='date']:not(.date_picker-html5) {
    &::-webkit-clear-button,
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
    }
  }
}

// Password.
.show-password {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 7px;
}

.form-footer {
  background: #f9f9f9;
  padding: 1rem 1rem 1.2rem;
  display: flex;
  justify-content: right;
  text-align: right;
  border-top: 1px dashed rgba(0, 0, 0, 0.04);

  // Align content to right.
  & > div:first-of-type {
    margin-left: auto;
  }

  button {
    margin-left: 0.5rem;
  }
}

.file-field {
  margin-left: 3rem;
}

// Overrides.
body {
  .input-field:not(.field-checkbox) .helper-text:not(.date-widget) {
    // Reduce space for helper text
    margin-bottom: 2rem;
  }

  [type='checkbox'].filled-in:checked + span:not(.lever):after {
    border: 2px solid $blue-mineral;
    background-color: $blue-mineral;
  }

  [type='checkbox'] + span:not(.lever) {
    padding-left: 25px;
  }

  // Checkboxes
  [type='checkbox'].filled-in:not(:checked) + span:not(.lever),
  [type='checkbox'].filled-in.tabbed:focus + span:not(.lever),
  [type='checkbox'].filled-in:checked + span:not(.lever),
  [type='checkbox'].filled-in.tabbed:focus:checked + span:not(.lever) {
    &:after {
      height: 16px;
      width: 16px;
      top: 4px;
      border-color: $block-text-secondary;
      box-shadow: none;
      border-radius: 100px;
    }
  }

  [type='checkbox'].filled-in:checked + span:not(.lever),
  [type='checkbox'].filled-in.tabbed:focus + span:not(.lever) {
    &:before {
      top: 2px;
      left: 0;
      @include fa;
      content: '\f00c';
      transform: none;
      font-size: 0.7rem;
      border: 0;
      width: initial;
      height: initial;
      color: white;
      margin-top: -0.17rem;
      margin-left: 0.2rem;
    }
  }

  .error-text,
  .input-field .error-text {
    color: $forms-error;
    text-align: right;
    margin-top: -0.5rem;
    margin-bottom: 0;
    font-size: 12px;
    min-height: 0;
  }

  .form-table {
    tr {
      border: none;
    }

    td {
      padding: 0;

      .row {
        .input-field {
          margin-bottom: 0;

          .helper-text {
            margin-bottom: 0;
          }
        }

        margin: 0;
        padding: 0;
      }

      & .amount-with-switch {
        display: flex;
        align-items: center;

        & > .switch {
          width: 5rem;
        }

        & > .value {
          flex-basis: 100%;
        }
      }

      &.form-currency {
        width: 5rem;

        select {
          color: $dark-gray;
          border-radius: 100px;
          padding: 0.5rem;
          height: auto;
        }
      }
    }
  }
}

// Select
.select-wrapper + label {
  top: -0.8rem;
}
