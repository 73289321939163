@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import 'variables';
@import 'mixins';

// Icons.
.icon {
  color: rgba(0, 0, 0, 0.4);
  display: inline-block;
  line-height: normal;
  font-size: 1.3rem;

  .fa:before {
    font-size: 80%;
    padding: 10%;
  }
}

// Allow to use font awesome icons in materialize input fields.
.input-field .prefix.fa {
  @include fa-margins;
}

// No double chevron in free version,
// Allow second chevron icon to have a "double" class.

.icon.double {
  .fa-chevron-right,
  .fa-chevron-left {
    margin-left: -0.6rem;
  }
}
