@font-face {
  font-family: 'Work Sans Regular';
  src: url('../../assets/fonts/Work_Sans/worksans-regular-webfont.woff2')
      format('woff2'),
    url('../../assets/fonts/Work_Sans/worksans-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Fredoka One';
  src: url('../../assets/fonts/FredokaOne/FredokaOne-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
