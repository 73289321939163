@import 'palette';

html {
  font-size: 0.9rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 1rem 1.5rem;
  font-weight: lighter;
  color: $text-navigation-title;
}

h1 {
  font-size: 2.5rem;
  font-weight: 300;
}

h2 {
  font-size: 1.8rem;
  font-weight: 300;
  padding-bottom: 0;
  color: $medium-gray;
}

h3 {
  font-size: 1.6rem;
  font-weight: bold;
  padding-bottom: 0;
  color: $medium-gray;
}

a {
  color: $dark-gray;
  cursor: pointer;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

hr {
  border-style: solid;
  border-width: 1px 0 0 0;
  margin: 1rem 0;
  color: rgba(0, 0, 0, 0.1);
}

select {
  cursor: pointer;
}

img {
  display: block;
}
