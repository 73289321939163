@import 'palette';
@import 'variables';

// Messages.
@each $type in $messages-types {
  $color: map-get($colors-messages-types, $type);

  .btn-#{$type} {
    background: $color;
  }
  .btn-#{$type}:hover {
    background: darken($color, 10%);
  }
  .btn-#{$type}:focus {
    background: darken($color, 20%);
  }

  .message-#{$type} {
    &,
    & * {
      color: $color;
    }
  }
}

.message {
  margin: $block-spacing;
  padding: 1rem;

  h3 {
    font-weight: lighter;
    padding: 0;
    margin: -0.5rem 0 0.5rem 0;
    opacity: 0.2;
  }

  &:last-of-type {
    margin-bottom: 2rem;
  }
}

.message-chip {
  padding: 0.4rem 0.8rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;

  &::before {
    @include fa;
    font-size: 1rem;
    margin: 0 0.4rem 0 -0.1rem;
    opacity: 0.5;
    float: left;
  }
}

// Messages.
@each $type in $messages-types {
  $color: map-get($colors-messages-types, $type);

  .message-#{$type} {
    border-bottom: 1px solid lighten($color, 30);
  }

  .message-chip-#{$type} {
    background: lighten(saturate($color, 40%), 60%);
    color: $color;
  }
}

:not(.message-chip-no-icon) {
  &.message-chip-warn::before {
    content: '\f071';
  }

  &.message-chip-info::before {
    content: '\f05a';
  }

  &.message-chip-error::before {
    content: '\f1e2';
  }

  &.message-chip-success::before {
    content: '\f058';
  }
}

