// Inspired from https://codepen.io/chorijan/pen/azVzPO

$ruby: #d8334a;
$grapefruit: #ed5565;
$bittersweet: #fc6e51;
$sunflower: #ffce54;
$straw: #e8ce4d;
$grass: #a0d468;
$mint: #48cfad;
$teal: #a0cecb;
$aqua: #4fc1e9;
$blue-jeans: #5d9cec;
$plum: #8067b7;
$lavender: #ac92ec;
$pink-rose: #ec87c0;
$dark-gray: #2f2f36;
$medium-gray: lighten($dark-gray, 50);
$light-gray: lighten($medium-gray, 25);
$charcoal: #3c3b3d;
$blue-mineral: rgb(36, 68, 92);
$input-focus-color: $teal;

$colors: (
  ruby: $ruby,
  grapefruit: $grapefruit,
  bittersweet: $bittersweet,
  sunflower: $sunflower,
  straw: $straw,
  grass: $grass,
  mint: $mint,
  teal: $teal,
  aqua: $aqua,
  blue-jeans: $blue-jeans,
  plum: $plum,
  lavender: $lavender,
  pink-rose: $pink-rose,
  light-gray: $light-gray,
  medium-gray: $medium-gray,
  dark-gray: $dark-gray,
  charcoal: $charcoal,
);

$forms-error: #940000;

@each $color in $colors {
  $name: nth($color, 1);
  $value: nth($color, 2);

  .background-#{$name} {
    background-color: $value;
  }

  .text-#{$name} {
    color: $value;
  }
}

$color-error: #610007;
$color-info: #314461;
$color-success: #2b5933;
$color-warn: #a77800;

$colors-messages-types: (
  'error': $color-error,
  'info': $color-info,
  'success': $color-success,
  'warn': $color-warn,
);

$text-default: #333;
$text-navigation-active: rgba(0, 0, 0, 0.6);
$text-navigation-title: rgba(0, 0, 0, 0.15);

$shadow-soft: -1px 1px 1px rgba(0, 0, 0, 0.3), -2px 2px 5px rgba(0, 0, 0, 0.2);

$shadow-block-inner: inset 0 10px 5px -12px rgba(0, 0, 0, 0.4);

$shadow-large-soft: -0.1rem 0.1rem 3.1rem rgba(0, 0, 0, 0.1),
  -0.1rem 0.1rem 0.4rem rgba(0, 0, 0, 0.1),
  -0.1rem 0.1rem 0.1rem rgba(0, 0, 0, 0.1);

$shadow-large-medium: -0.1rem 0.2rem 3.1rem rgba(0, 0, 0, 0.2),
  0rem 0.1rem 0.4rem rgba(0, 0, 0, 0.3),
  -0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);

$shadow-large-bottom: 0rem -0.2rem 3.1rem rgba(0, 0, 0, 0.2),
  0rem -0.1rem 0.4rem rgba(0, 0, 0, 0.1), -0.4rem 0rem 0.1rem rgba(0, 0, 0, 0);
