@import 'palette';
@import 'mixins';

.btn,
.btn-large,
.btn-small {
  @include text-ellipsis;
  background-color: $dark-gray;
  border-radius: 100px;
  box-shadow: $shadow-soft;
  color: white;
  max-width: 100%;

  &:hover,
  &:focus {
    background-color: $medium-gray;
  }

  &:not(:last-of-type) {
    margin-right: 0.5rem;
  }

  .icon {
    color: $medium-gray;

    .fa {
      line-height: 1rem;
    }
  }
}

.has-chevron {
  padding-right: 1em;

  &::after {
    @include fa;
    font-size: 1em;
    padding-left: 0.5rem;
    color: $medium-gray;
  }

  &.has-chevron-up::after {
    content: '\f077';
  }

  &::after,
  &.has-chevron-down::after {
    content: '\f078';
  }
}

.btn-full-width {
  width: 100%;
}

.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: $medium-gray;
}

.btn-input-upload-file {
  position: relative;

  input,
  input::-webkit-file-upload-button {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.btn-action-centered {
  margin: 2rem auto 3rem;
  display: block;
  max-width: 25rem;
}
