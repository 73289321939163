.splash-drop {
  position: fixed;
  z-index: 1000;
  border-radius: 1000px;
  font-family: Helvetica, sans-serif;
}

.splash-drop-tail {
  border: 1px solid rgb(200, 200, 200);
}

.splash-drop-sprinkle-mouse,
.splash-drop-group-mouse,
.splash-drop-group-node {
  background: #b7bffb;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
  border: 2px solid white;
}
