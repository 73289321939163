@import '~materialize-css/dist/css/materialize.css';
//@import '~materialize-css/sass/materialize';

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'), local('MaterialIcons-Regular'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2)
      format('woff2'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.woff)
      format('woff'),
    url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf)
      format('truetype');
}

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  color: rgba(0, 0, 0, 0.4);

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

// Hide icons until loaded.
.wf-loading,
.wf-materialicons-n4-inactive {
  .material-icons {
    display: none;
  }
}

// Allow fields type month, like date fields.
input[type='month']:not(.browser-default) {
  @extend input, [type='date'];
}
