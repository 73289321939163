// Base extension
@import '../../../src/Wex/BaseBundle/Resources/css/mains/default/main';

// Global imports
@import '../../../src/Wex/BaseBundle/Resources/css/partials/modal';

// Global project imports
@import '../../partials/fonts';
@import '../../partials/splash/drop';

body {
  background: black;

  #content-black {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: fixed;
    width: 100%;
    flex-direction: column;

    & > a {
      color: white;
    }
  }
}

.center-container-white {
  background: white;
  border-radius: 1rem;
  width: 30rem;
}
